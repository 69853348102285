<template>
  <div class="flex-column">
    <div class="app-container flex-item">
      <el-row class="fh">
        <el-col :span="7" class="fh ls-border">
          <el-card shadow="never" class="card-body-none fh" style="overflow-y: auto">
            <div slot="header" class="clearfix">
              <el-button type="text" style="padding: 0 11px" @click="getAllMenus">所有菜单>></el-button>
            </div>
            <tree-table ref="menuTable" highlight-current-row :data="modulesTree" :columns="columns" @row-click="treeClick" border>
            </tree-table>
          </el-card>
        </el-col>
        <el-col :span="14" class="fh">
          <div class="bg-white fh">
            <div class="form-item flex-container">
              <label class="label">公司名称：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>
            <div class="form-item flex-container">
              <label class="label">注册地址：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>
            <div class="form-item flex-container">
              <label class="label">经营/生产地址：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>
            <div class="form-item flex-container">
              <label class="label">通讯地址：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>
            <div class="form-item flex-container">
              <el-radio v-model="radioValue" label="option1" class="op1">是否有分支机构</el-radio>
              <el-radio v-model="radioValue" label="option2" class="op2">是否出口</el-radio>
              <label class="label addd">出口国家：</label>
              <el-input class="short-textbox my-input2" placeholder="请输入内容"></el-input>              
            </div>
            <div class="form-item flex-container">
              <label class="label">经营范围：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>
            <div class="form-item flex-container">
              <label class="label addd">营业执照：</label>
              <el-button type="primary" class="btn acc" size="mini">上传</el-button>
            </div>
            <div class="form-item flex-container">
              <label class="label">企业资质：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>   
            <div class="form-item flex-container">
              <label class="label">公司简介：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>  
            <div class="form-item flex-container">
              <label class="label">公司人数：</label>
              <el-input class="short-textbox my-input" placeholder="请输入内容"></el-input>
              <el-button type="primary" class="btn" size="mini">编辑</el-button>
            </div>         
          </div>
        </el-col>
      </el-row>
    </div>
  </div>

</template>

<script>
  import treeTable from '@/components/TreeTable'
  //import Pagination from '@/components/Pagination'
  import {
    listToTreeSelect
  } from '@/utils'
  import extend from "@/extensions/delRows.js"
  import * as modules from '@/api/modules'
  import * as login from '@/api/login'
  //import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  //import Sticky from '@/components/Sticky'
  //import permissionBtn from '@/components/PermissionBtn'
  import elDragDialog from '@/directive/el-dragDialog'
  import iconData from '@/assets/public/css/comIconfont/iconfont.json'
  export default {
    name: 'module',
    components: {
      //Sticky,
      //permissionBtn,
      //Treeselect,
      treeTable,
      //Pagination
    },
    mixins: [extend],
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        iconData: iconData,
        classBtns: [{
          value: 'primary'
        },{
          value: 'danger'
        },{
          value: 'success'
        },{
          value: 'info'
        },{
          value: 'warning'
        }], // 按钮样式
        normalizer(node) {
          // treeselect定义字段
          return {
            label: node.name,
            id: node.id,
            children: node.children
          }
        },
        columns: [
          // treetable的列名
          {
            text: '',
            value: 'name'
          },
          // {
          //   text: '2',
          //   value: 'code'
          // },
          // {
          //   text: '3',
          //   value: 'url'
          // }
        ],
        selectMenus: [], // 菜单列表选中的值
        tableKey: 0,
        list: [], // 菜单列表
        total: 0,
        currentModule: null, // 左边模块treetable当前选中的项
        listLoading: false,
        listQuery: {
          // 查询条件
          page: 1,
          limit: 20,
          orgId: '',
          key: undefined
        },
        apps: [],

        showDescription: false,
        modules: [], // 用户可访问到的模块列表
        modulesTree: [], // 用户可访问到的所有模块组成的树
        temp: {
          // 模块临时值
          id: undefined,
          cascadeId: '',
          url: '',
          code: '',
          sortNo: 0,
          iconName: '',
          parentId: null,
          name: '',
          status: 0,
          isSys: false
        },
        menuTemp: {
          // 菜单临时值
          id: undefined,
          url: '',
          icon: '',
          code: '',
          moduleId: null,
          name: '',
          status: 0,
          sort: 0
        },
        lastParentId:'',  //最后一次选中的上级模块Id
        dialogFormVisible: false, // 模块编辑框
        dialogStatus: '',
        dialogMenuVisible: false, // 菜单编辑框
        dialogMenuStatus: '',

        chkRoot: false, // 根节点是否选中
        treeDisabled: false, // 树选择框时候可用
        textMap: {
          update: '编辑',
          create: '添加'
        },
        rules: {
          name: [{
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          }]
        },
        downloadLoading: false
      }
    },
    computed: {
      isRoot: {
        get() {
          return this.chkRoot
        },
        set(v) {
          this.chkRoot = v
          if (v) {
            this.temp.parentName = '根节点'
            this.temp.parentId = null
          }
        }
      },
      modulesTreeRoot() {
        const root = [{
          name: '根节点',
          parentId: '',
          id: ''
        }]
        return root.concat(this.modulesTree)
      },
      currentPageMenus: {
        get() {
          var start = (this.listQuery.page - 1) * this.listQuery.limit
          var end = (this.listQuery.page * this.listQuery.limit)
          var result = this.list.slice(start, end)
          return result
        }
      },
      dpSelectModule: {
        // 模块编辑框下拉选中的模块
        get: function () {
          if (this.dialogStatus === 'update') {
            return this.temp.parentId || ''
          } else {
            return this.lastParentId
          }
        },
        set: function (v) {
          if (v === undefined || v === null || !v) {
            // 如果是根节点
            this.temp.parentName = '根节点'
            this.temp.parentId = null
            return
          }
          this.temp.parentId = v
          this.lastParentId = v
          var parentname = this.modules.find(val => {
            return v === val.id
          }).name
          this.temp.parentName = parentname
        }
      }
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'info',
          1: 'danger'
        }
        return statusMap[status]
      }
    },
    created() {
      // this.getList()
    },
    mounted() {
      this.getModulesTree()
    },
    methods: {
      handleChangeTempIcon(item) {
        this.temp.iconName = item.font_class
        this.$refs.popoverModule.doClose()
      },
      handleChangeMenuTempIcon(item) {
        this.menuTemp.icon = item.font_class
        this.$refs.popoverMenu.doClose()
      },
      rowClick(row) {
        // this.$refs.mainTable.clearSelection()
        // this.$refs.mainTable.toggleRowSelection(row)
        console.log(row);
      },
      treeClick(data) {
        // 左侧treetable点击事件
        // this.currentModule = data
        // this.currentModule.parent = null
        // this.getList()
        console.log(data);
      },
      getAllMenus() {
        // this.currentModule = null
        // this.getList()
      },
      handleSelectionChange(val) {
        this.selectMenus = val
      },
      onBtnClicked: function (domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd':
            this.handleCreate()
            break
          case 'btnEdit':
            if (this.currentModule === null) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleUpdate(this.currentModule)
            break
          case 'btnDel':
            if (this.currentModule === null) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelete(this.currentModule)
            break
          case 'btnAddMenu':
            this.handleAddMenu()
            break
          case 'btnEditMenu':
            if (this.selectMenus.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleEditMenu(this.selectMenus[0])
            break
          case 'btnDelMenu':
            if (this.selectMenus.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelMenus(this.selectMenus)
            break
          default:
            break
        }
      },
      getList() {
        this.listLoading = true
        var moduleId = this.currentModule === null ? null : this.currentModule.id
        modules.loadMenus(moduleId).then(response => {
          this.list = response.result
          this.total = response.result.length
          this.listLoading = false
        })
      },
      getModulesTree() {
        var _this = this // 记录vuecomponent
        login.getModules().then(response => {
          _this.modules = response.result.map(function (item) {
            return {
              sortNo: item.sortNo,
              id: item.id,
              name: item.name,
              iconName: item.iconName,
              parentId: item.parentId || null,
              code: item.code,
              url: item.url,
              cascadeId: item.cascadeId,
              isSys: item.isSys
            }
          })
          console.log("this_module1",_this.modules);
          
          var modulestmp = JSON.parse(JSON.stringify(_this.modules))
          _this.modulesTree = listToTreeSelect(modulestmp).sort((a, b) => a.sortNo - b.sortNo)
          console.log("modulesTree",_this.modulesTree);
          const arr = _this.modulesTree.filter(item => item.children && item.children.length > 0)
          // 默认选中第一个
          _this.$refs.menuTable.rowClick(arr[0].children[0])
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      resetTemp() {
        this.temp = {
          id: undefined,
          cascadeId: '',
          url: '',
          iconName: '',
          code: '',
          parentId: null,
          name: '',
          status: 0
        }
      },
      resetMenuTemp() {
        this.menuTemp = {
          id: undefined,
          cascadeId: '',
          icon: '',
          url: '',
          code: '',
          moduleId: this.currentModule ? this.currentModule.id : null,
          name: '',
          status: 0,
          sort: 0
        }
      },

      // #region 模块管理
      handleCreate() {  // 弹出添加框
        
        this.dpSelectModule = this.lastParentId  
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() {
        // 保存提交
        this.$refs['dataForm'].validate(valid => {
          var _this = this
          if (valid) {
            if (_this.temp.url.indexOf('http') > -1 && !_this.temp.code) {
              _this.$message.error('请输入模块标识')
              return
            }
            //如果打开弹框后，没有做任何选择操作，父级为空
            _this.temp.parentId = _this.temp.parentId || _this.lastParentId

            modules.add(_this.temp).then(response => {
              // 需要回填数据库生成的数据
              _this.temp.id = response.result.id
              _this.temp.cascadeId = response.result.cascadeId
              _this.list.unshift(_this.temp)
              _this.dialogFormVisible = false
              _this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              _this.getModulesTree()
            })
          }
        })
      },
      handleUpdate(row) {
        // 弹出编辑框
        this.temp = Object.assign({}, row) // copy obj
        if (this.temp.children) { // 点击含有子节点树结构时，带有的children会造成提交的时候json死循环
          this.temp.children = null
        }

        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.dpSelectModule = this.temp.parentId
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      updateData() {
        // 更新提交
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            const tempData = Object.assign({}, this.temp)
            if (tempData.url.indexOf('http') > -1 && !tempData.code) {
              this.$message.error('请输入模块标识')
              return
            }
            modules.update(tempData).then(() => {
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 2000
              })

              this.getModulesTree()
              for (const v of this.list) {
                if (v.id === this.temp.id) {
                  const index = this.list.indexOf(v)
                  this.list.splice(index, 1, this.temp)
                  break
                }
              }
            })
          }
        })
      },
      handleDelete(row) {
        var _this = this
        _this.delrows(modules, [row], () => {
          _this.getModulesTree()
        })
      },
      // #end region

      // #region 菜单管理
      handleAddMenu() {
        // 弹出添加框
        this.resetMenuTemp()
        this.dialogMenuStatus = 'create'
        this.dialogMenuVisible = true
        this.$nextTick(() => {
          this.$refs['menuForm'].clearValidate()
        })
      },
      addMenu() {
        // 保存提交
        this.$refs['menuForm'].validate(valid => {
          if (valid) {
            modules.addMenu(this.menuTemp).then(response => {
              // 需要回填数据库生成的数据
              this.menuTemp.id = response.result.id
              this.list.unshift(this.menuTemp)
              this.dialogMenuVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
            })
          }
        })
      },
      handleEditMenu(row) {
        // 弹出编辑框
        this.menuTemp = Object.assign({}, row) // copy obj
        this.dialogMenuStatus = 'update'
        this.dialogMenuVisible = true
        this.$nextTick(() => {
          this.$refs['menuForm'].clearValidate()
        })
      },
      updateMenu() {
        // 更新提交
        this.$refs['menuForm'].validate(valid => {
          if (valid) {
            const tempData = Object.assign({}, this.menuTemp)
            modules.updateMenu(tempData).then(() => {
              this.dialogMenuVisible = false
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 2000
              })

              for (const v of this.list) {
                if (v.id === this.menuTemp.id) {
                  const index = this.list.indexOf(v)
                  this.list.splice(index, 1, this.menuTemp)
                  break
                }
              }
            })
          }
        })
      },
      handleDelMenus(rows) {
        // 多行删除
        modules.delMenu(rows.map(u => u.id)).then(() => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
          rows.forEach(row => {
            const index = this.list.indexOf(row)
            this.list.splice(index, 1)
          })
        })
      }
      // #end region
    }
  }
</script>

<style lang="scss">

  .page-container {
    background-color: #ffffff; /* 将页面背景色设置为白色 */
  }

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }

  .clearfix:after {
    clear: both;
  }

  .el-card__header {
    padding: 12px 20px;
  }

  .selectIcon-box {
    text-align: center;
    border: 1px solid #eeeeee;
    border-right: 0;
    border-bottom: 0;

    .el-col {
      padding: 10px 0;
      border-right: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;

      &.active {
        .iconfont {
          color: #409EFF;
        }
      }
    }

    .iconfont {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .custom-icon-input::before {
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .custom-select-icon{
    position: absolute;
    top: 8px;
    right:40px;
    z-index: 10;
  }
  .form-item {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin-bottom: 15px; /* 增加每个 form-item 之间的下边距 */
}

.form-item .btn {
  margin-left: -15px; /* 调整按钮与文本框之间的左边距 */
}


.custom-theme .el-input{
  width: 750px;
}

.label {
  width: 135px; /* 可根据实际情况调整 */
  margin-left: 30px; /* 调整 label 和输入框之间的间距 */
}

.my-input.short-textbox input {
  width: 700px; /* 自定义文本框宽度 */
  border: none; /* 隐藏文本框的边框线 */
  border-bottom: 1px solid #ccc; /* 只显示文本框的下边框线 */
}

.my-input2.short-textbox input {
  width: 170px; /* 自定义文本框宽度 */
  border: none; /* 隐藏文本框的边框线 */
  border-bottom: 1px solid #ccc; /* 只显示文本框的下边框线 */
}

// .btn {
//   margin-left: -5px; /* 调整按钮与文本框之间的左边距 */
//   margin-right: 5px; /* 恢复按钮之间的默认间距 */
// }


.op1{
  margin-left: 250px;
}
.addd{
  width: 190px;
}
.el-radio.is-checked {
  border-color: #409EFF; /* 选中状态下的边框颜色 */
  background-color: #409EFF; /* 选中状态下的填充颜色 */
}
.acc{
  margin-left: 250px;
}

/* 样式表中添加的放大字体的样式 */
.form-item label {
  font-size: 17px; /* 设置标签的字体大小为 16 像素 */
}

.short-textbox input {
  font-size: 16px; /* 设置输入框的字体大小为 14 像素 */
}

/* 设置是否有分支机构和是否出口文本的字体大小 */
.addd {
  width: 220px; /* 调整字体大小为 16px */
}

.app-container.el-row.el-row--flex {
  .bg-white.fh {
    width: 100%;
  }
}
</style>