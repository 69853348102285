var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-column" }, [
    _c(
      "div",
      { staticClass: "app-container flex-item" },
      [
        _c(
          "el-row",
          { staticClass: "fh" },
          [
            _c(
              "el-col",
              { staticClass: "fh ls-border", attrs: { span: 7 } },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "card-body-none fh",
                    staticStyle: { "overflow-y": "auto" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0 11px" },
                            attrs: { type: "text" },
                            on: { click: _vm.getAllMenus },
                          },
                          [_vm._v("所有菜单>>")]
                        ),
                      ],
                      1
                    ),
                    _c("tree-table", {
                      ref: "menuTable",
                      attrs: {
                        "highlight-current-row": "",
                        data: _vm.modulesTree,
                        columns: _vm.columns,
                        border: "",
                      },
                      on: { "row-click": _vm.treeClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-col", { staticClass: "fh", attrs: { span: 14 } }, [
              _c("div", { staticClass: "bg-white fh" }, [
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("公司名称："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("注册地址："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("经营/生产地址："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("通讯地址："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c(
                      "el-radio",
                      {
                        staticClass: "op1",
                        attrs: { label: "option1" },
                        model: {
                          value: _vm.radioValue,
                          callback: function ($$v) {
                            _vm.radioValue = $$v
                          },
                          expression: "radioValue",
                        },
                      },
                      [_vm._v("是否有分支机构")]
                    ),
                    _c(
                      "el-radio",
                      {
                        staticClass: "op2",
                        attrs: { label: "option2" },
                        model: {
                          value: _vm.radioValue,
                          callback: function ($$v) {
                            _vm.radioValue = $$v
                          },
                          expression: "radioValue",
                        },
                      },
                      [_vm._v("是否出口")]
                    ),
                    _c("label", { staticClass: "label addd" }, [
                      _vm._v("出口国家："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input2",
                      attrs: { placeholder: "请输入内容" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("经营范围："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label addd" }, [
                      _vm._v("营业执照："),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn acc",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("上传")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("企业资质："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("公司简介："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-item flex-container" },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("公司人数："),
                    ]),
                    _c("el-input", {
                      staticClass: "short-textbox my-input",
                      attrs: { placeholder: "请输入内容" },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }